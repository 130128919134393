@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,600;0,700;1,400&display=swap');

#__next {
    width: 100%;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

[role='button'],
[role='button'] * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#otpless-floating-button {
    display: none;
    /* display: block;
    top: auto !important;
    left: 50%;
    right: 40px !important;
    bottom: 40px;
    transform: translateX(-50%); */
}

#otpless-floating-button.show {
    display: block;
    top: auto !important;
    left: 50%;
    right: auto !important;
    bottom: 40px;
    transform: translateX(-50%);
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 221.2 83.2% 53.3%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 221.2 83.2% 53.3%;
        --radius: 0.5rem;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
        --radius: 0.5rem;
    }
    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 217.2 91.2% 59.8%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 224.3 76.3% 48%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }

    html,
    body {
        padding: 0;
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-optical-sizing: auto;
        font-weight: normal;
        font-style: normal;
        width: 100%;
        height: 100%;
    }
}

.bg-image-inlogin {
    background-image: url('../public/login-image/login-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 1000px; */
    /* height: 610px; */
    /* margin: auto; */
    max-width: 900px;
    width: 100%;

    border-radius: 50px;
}

/* Styles for LTR */
:root[dir='ltr'] .yourClass {
    text-align: left;
}

/* Styles for RTL */
:root[dir='rtl'] .yourClass {
    text-align: right;
}

:root[dir='ltr'] .yourClassLabel {
    text-align: center;
}

/* Styles for RTL */
:root[dir='rtl'] .yourClassLabel {
    text-align: right;
}

:root[dir='ltr'] .yourClassAlignAction {
    justify-content: center;
}

/* Styles for RTL */
:root[dir='rtl'] .yourClassAlignAction {
    justify-content: right;
}

:root[dir='rtl'] input {
    text-align: right; /* Align text to the right for RTL */
    direction: rtl; /* Ensure input text flows right to left */
}

.only-for-warehouse .ant-select-selector,
.only-for-warehouse .ant-input {
    border: 1px solid #e2e2e2 !important; /* Default border */
    transition: border-color 0.2s ease-in-out;
}

.addred-color-oninput .ant-input {
    border: 1px solid red !important;
}

.only-for-warehouse .ant-select-selector:focus,
.only-for-warehouse .ant-input:focus,
.only-for-warehouse .ant-select-focused .ant-select-selector {
    border-color: #b2b2b2 !important; /* Light pink border */
    box-shadow: none !important; /* Remove default focus shadow */
    outline: none !important;
}
.ant-select-selector {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    outline: none !important;
    border-radius: 6px !important;
}

.ant-select-selector:focus {
    border: 5px solid blue !important;
    outline: 5px solid blue !important;
}

.history,
.ant-select-selection-placeholder {
    color: black !important;
    font-weight: 400;
    font-size: 0.875rem;
    /* font-weight: 400; */
}
